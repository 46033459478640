import React, { useContext, useState } from 'react';

import {
  Button,
  FlexboxGrid,
  IconButton,
  Icon
} from 'rsuite';

import { MenuType } from '../Types';

import jump from 'jump.js'
import DeviceContext from '../Library/DeviceContext';
import { useLocation, useHistory } from 'react-router-dom';
import { modalview } from 'react-ga';

// import Logotipo from '../ContentDeliveryNetwork/mobile_logotipo.png'

const Menu: MenuType[] = [
  /*{
    appearance: "subtle",
    text: '¿Quién soy?',
    goJump: 'aboutme'
  },*/
  {
    appearance: "subtle",
    text: 'Testimonios',
    goJump: 'testimonios'
  },
  {
    appearance: "subtle",
    text: 'Publicaciones',
    goJump: 'publicaciones'
  },
  {
    appearance: "subtle",
    text: 'Cirugías',
    goJump: 'cirugias'
  },
  {
    appearance: "subtle",
    text: 'Videos',
    goJump: '#videos'
  },
  {
    color: "orange",
    text: 'Reserva tu hora',
    goJump: 'reservahoras'
  },
]

type MenuProps = {
    logo: JSX.Element | any
}

const Header = ({ logo }: MenuProps) => {
  const { isMobile, YOffset, setLoading } = useContext(DeviceContext)
  const [toggleMenu, setToggleMenu] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const go = (e: any, goJump: string) => {
      // Previene acción por defecto
      e.preventDefault()
      setToggleMenu(false)
      
      const futureTarget = goJump.replace('#', '')

      /**
       * Redirecciona al home.
       */
      if(location.pathname !== '/' && futureTarget.length === goJump.length){
        setLoading(true)
        history.push('/')
        setTimeout(() => {
          setLoading(false)
          setTimeout(() => {
            jump(`#LeaderBox--${goJump}`, {
              offset: -68
            })
          }, 300)
        }, 1200)
        return false
      }

      // Valida si es un redirect a otra página
      if (!goJump.indexOf('#') && futureTarget !== location.pathname){
        jump(-5000)
        history.push(futureTarget)
        return false
      }

      modalview(`/${goJump}`)

      jump(`#LeaderBox--${goJump}`, {
        offset: -68
      })
      
      return true
  }
  
  // const localLogo = isMobile ? Logotipo : logo
  const localLogo = logo

  return (
    <div className="Header" style={{ height: toggleMenu ? '260px' : '68px', background: (YOffset === 0 && !toggleMenu ? '' : '#FFF') }}>
      <FlexboxGrid justify="space-between">
        <FlexboxGrid className="Menu Menu--LOGO" justify="space-between" style={{ width: (isMobile ? '75%' : '30%'), marginTop: '8px' }}>
          <img src={localLogo} height={24} alt='Logotipo superior' onClick={(e) => go(e, 'home')} style={{cursor: 'pointer'}} />
        </FlexboxGrid>
        {isMobile && <IconButton onClick={() => setToggleMenu(!toggleMenu)} color='orange' icon={<Icon icon="align-justify" />} active={toggleMenu} />}
        {(!isMobile || (isMobile && toggleMenu)) && <FlexboxGrid className="Menu Menu--Options" justify="space-between" style={{ width: (isMobile ? '100%' : '70%') }}>
          {Menu && Menu.map(({ goJump, text, appearance, color }) =>
            <Button block={isMobile} onClick={(e) => go(e, goJump)} appearance={appearance} color={color} key={`Button-${text?.toLocaleLowerCase()}`} >{text}</Button>
          )}
        </FlexboxGrid>}
      </FlexboxGrid>
    </div>
  );
}

export default Header;
