import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.scss';
import { DeviceProvider } from './Library/DeviceContext';

import LogotipoText from './ContentDeliveryNetwork/logotipo_only_text.png'

import Home from './Pages/Home';
import { Header } from './Components';
import Hombro from './Pages/Hombro';
import Codo from './Pages/Codo';
import Videos from './Pages/Videos';
import { Loader } from 'rsuite';
import GoogleAnalytics from './Components/GoogleAnalytics';

function App() {
  const [width, setWidth] = useState(window.innerWidth)
  const [YOffset, setYOffset] = useState(window.pageYOffset)
  // Mobile: < 480
  // Tablet > 480 && < 768
  // Desktop > 960
  const breakpoint = 960
  const [isMobile, setIsMobile] = useState(width < breakpoint)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
    window.addEventListener('scroll', () => setYOffset(window.pageYOffset))
  }, [])
  
  useEffect(() => {
    setIsMobile(width < breakpoint)
  }, [width])

  useEffect(() => {
    document.body.style.overflowY = loading ? 'hidden' : 'scroll'
  }, [loading])

  /*useEffect(() => {
    ga('send', 'pageview', location.pathname)
    console.log(location.pathname)
  }, [location])*/

  return (
    <DeviceProvider value={{isMobile, YOffset, loading, setLoading}}>
      <div className={`Website--Full isLoading--${loading}`}>
      {loading && <Loader backdrop inverse center content="Actualizando información..." />}
      <Router>
        <GoogleAnalytics />
        <div className={`App ${isMobile ? 'isMobile' : 'isDesktop'}`}>
          <Header logo={LogotipoText} />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/cirugias-hombro" exact>
              <Hombro />
            </Route>
            <Route path="/cirugias-codo" exact>
              <Codo />
            </Route>
            <Route path="/videos" exact>
              <Videos />
            </Route>
          </Switch>
        </div>
      </Router>
      </div>
    </DeviceProvider>
  );
}

export default App;
