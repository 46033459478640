import React from 'react'

import { LeaderBoxType } from '../Types'
import { FlexboxGrid } from 'rsuite'

import SeparatorDiv from '../ContentDeliveryNetwork/separator.png'

const LeaderBox = ({ id, Information, align, CallToAction, height, imageModule }: LeaderBoxType) => {
    const hasImageSrc = imageModule && typeof imageModule === 'string'
    // const imageInstance = hasImageSrc ? <img src={`${imageModule}`} alt={`Imagen de LeaderBox${id}`} /> : imageModule
    // @ts-ignore
    const imageURL: string = hasImageSrc && imageModule

    const isFirstImage = align !== 'left'
    const order = isFirstImage ? 1 : 2
    const nextOrder = isFirstImage ? 2 : 1

    height = height ? height : 566

    return <FlexboxGrid id={`LeaderBox--${id}`} key={`LeaderBox--${id}`} className='LeaderBox--Item'>
        <FlexboxGrid.Item
            className={`LeaderBox--Information LeaderBox--Information--${align}`}
            style={{
                height
            }}
            colspan={align === 'full' ? 24 : 12}
            order={order}
        >
            {Information}
        </FlexboxGrid.Item>
        {align !== 'full' &&
        <FlexboxGrid.Item
            className={`LeaderBox--Image LeaderBox--Image--${align}`}
            style={{
                height,
                ...(hasImageSrc ? {
                    backgroundImage: `url(${imageURL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat'
                }  : {})
            }}
            colspan={12}
            order={nextOrder}
        >
            {!hasImageSrc && imageModule}
        </FlexboxGrid.Item>}
        <img src={SeparatorDiv} alt='Separador' className='LeaderBox--Separator' />
    </FlexboxGrid>
}

export default LeaderBox