import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import { ga } from "react-ga"

function useGAViews() {
    const location = useLocation()
    useEffect(() => {
        ga('send', 'pageview', location.pathname)
        console.log(location.pathname)
    }, [location])
}

export default useGAViews