import React, { useState, useEffect, useContext } from 'react';
import DeviceContext from '../Library/DeviceContext';

const Videos = () => {
    const [ready, setReady] = useState(0)
    const { setLoading } = useContext(DeviceContext)
    
    useEffect(() => {
        setLoading(ready !== 3)
    }, [ready, setLoading])

    return <div className="Videos--Page">

        <iframe
            width="560"
            height="315"
            title="Arthoscopy-Assisted Latissimus Dorsi Transfer"
            src="https://www.youtube.com/embed/6YcC1f6ohzo?controls=0"
            frameBorder="0"
            onLoad={() => {
                setReady(ready+1)
            }}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>

        <p>Te invito a conocer más sobre Arthroscopy-Assisted Latissimus Dorsi Transfer for Irreparable Subscapularis Tears</p>

        <iframe
            width="560"
            height="315"
            title="Técnica Quirúrgica Latarjet Artroscópico"
            src="https://www.youtube.com/embed/c0WFlKwxHtw?controls=0"
            frameBorder="0"
            onLoad={() => {
                setReady(ready+1)
            }}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>

        <p>Te invito a conocer más sobre Cirugía artroscopica de Latarjet - Bankart, revisa nuestra primera serie clínica publica en la Revista Artroscopia, medio oficial de SLARD, descargala en publicaciones.</p>

        <iframe
            width="560"
            height="315"
            title="HS Remplissage: Portal Posterior"
            src="https://www.youtube.com/embed/BgnsGALx5NQ?controls=0"
            frameBorder="0"
            onLoad={() => {
                setReady(ready+1)
            }}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>

        <p>Te invito a conocer más sobre Tecnica de Remplissage sin cambio de portal, full visión desde el portal posterior.</p>

        
    </div>
}
export default Videos