import React, { useState } from 'react'
import { Form, FormGroup, ControlLabel, FormControl, Button, Icon, SelectPicker, Input } from 'rsuite'

import './NewsletterContact.scss'
// import DeviceContext from '../Library/DeviceContext'

const NewsletterContact = () => {
    const [isNewsletter, setIsNewsletter] = useState(true)
    // const { isMobile } = useContext(DeviceContext)

    
  /**
   * Nombre - Apellido - Correo
   * Consultas Médicas / Sugerencias y Felicitaciones / Otros
   */

   const types: any[] = [
        {
           'label': 'Información y Otros',
           'value': 0
        },
        {
            'label': 'Consultas Médicas',
            'value': 1
        },
        {
            'label': 'Sugerencias y Felicitaciones',
            'value': 2
        },
   ]

    return <>
        <div className={`Newsletter--Left${!isNewsletter ? ' isContactForm' : ' isNewsletter'}`}>
            <h1>
                <Icon icon={isNewsletter ? 'envelope-open-o' : 'group'} size="4x" />
                <small>{isNewsletter ? 'Suscríbete a mi boletín' : 'Formulario de contacto'}</small><br />
                <span>{isNewsletter ? 'Suscríbete' : '¡Hablemos!'}</span>
            </h1>
            <p>
                {isNewsletter && <>Te invito a suscribirte al boletín de <strong>Cirugía Hombro y Codo</strong>, para que estés al 
                tanto de mis últimas novedades, publicaciones y artículos de interés.</>}
                {!isNewsletter && <>¿Tienes alguna consulta, sugerencia u otro comentario sobre <strong>Cirugía Hombro y Codo</strong>? 
                conversemos completando el siguiente formulario de contacto.</>}
            </p>
            <Button appearance='ghost' color='orange' onClick={() => setIsNewsletter(!isNewsletter)}>
                {isNewsletter ? '¿Necesitas conversar conmigo?' : 'Suscríbete a mi boletín'}
            </Button>
        </div>

        <Form className={`Newsletter--Right${!isNewsletter ? ' isContactForm' : ' isNewsletter'}`} fluid>
            <div className='Newsletter--Fields'>
                <div className='Newsletter--LeftForm'>
                    {!isNewsletter && <>
                        <FormGroup>
                            <ControlLabel>Nombre</ControlLabel>
                            <FormControl name="personname" />
                        </FormGroup>
                    </>}

                    <FormGroup>
                        <ControlLabel>Correo electrónico</ControlLabel>
                        <FormControl name="username" />
                    </FormGroup>

                    {!isNewsletter && <>
                        <FormGroup>
                            <ControlLabel>Tipo de Contacto</ControlLabel>
                            <SelectPicker
                                data={types}
                                searchable={false}
                                placement='topStart'
                                placeholder='Selecciona motivo de contacto...'
                                name="personname"
                                block
                            />
                        </FormGroup>
                    </>}
                </div>

                {!isNewsletter && <FormGroup>
                    <ControlLabel>Comentarios</ControlLabel>
                    <Input componentClass="textarea" rows={3} placeholder="Comentarios, consultas o mensaje en general..." />
                </FormGroup>}
            </div>
            

            <Button color='orange'>{isNewsletter ? 'Suscribir' : 'Enviar'}</Button>
        </Form>
      </>
}

export default NewsletterContact