import React from 'react';

const Hombro = () => {
    return <div className="Cirugias--Page">
        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/Manguitorotador.jpeg" alt="Patología Manguito Rotador" />
            <h3>Patología Manguito Rotador</h3>
            <p>
                Las roturas de los tendones del manguito rotador aguda y/o cronicas, si se presentan con sintomas, como dolor, debilidad y perdida progresiva del rango de movilidad, en general son de manejo quirurgico. El estandar actual es realizar una reparación artroscopica  con diferentes sistemas de fijación entre el tendon roto o desinserto y su huella nativa. La opcion que le ofrezco a los pacientes en realizar la reparación con una tecnica TOE, la cual permite optimizar la interfase tendon hueso, aumentando el area de cobertura y la presion de contacto, con el fin de obtener mejores tasas de cicatrización del tendón, en nuestra población, realizando esta tecnica obtenemos tasas de cicatrizacion del 100% en roturas inferior a 1cm, 96% entre 1 y 3 cm y 82% en roturas sobre los 3 cm. Con recuperacion muy satisfactoria de indices funcionales como el indice de Constant alcanzando valores promedios de 83 puntos. Te invito a revisar nuestros resultados, los cuales fueron aceptados y pronto seran publicados por la Revista Artroscopia. Medio de difusion ofical de la sociedad latinoamericana de artroscopia, rodilla y deporte.
            </p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/inestabilidad.jpeg" alt="Inestabilidad" />
            <h3>Inestabilidad</h3>
            <p>
            La inestabildiad recurrente de hombro tanto anterior como posterior siempre es un ran desafio, en general , si presentas más de dos episodios de luxación o presentas sintomas recurrentes como dolor o sensacion de “subluxacion”, la recomendación es realizar una cirugía de reparación. La definicion de la tecnica a utilizar depende de tu perfil como paciente (edad, tipo y regularidad de deporte que realizas) y las lesiones estructurales que tengas producto de la inestabilidad  (lesiones óseas o ligamentarias). Las opciones van desde la reparacion labral artroscopica aislada, pasando por tecnicas artroscopicas complementarias como el Remplissage (te invitamos a ver el video de esta tecnica, en la opcion videos) hasta cirugías de reconstrucción ósea como el Latarjet, tecnica francesa que remplaza la lesion del hueso de la glenoides que favorece la luxación. Fui pionero en Chile en realizar esta técnica de manera artroscopica, realizando nuestro primer caso en Enero de 2015, te invitamos a ver los videos de esta compleja tecnica en la opción videos, tambien en coonocer nuestros resultados en la primera experiencia Chilena publicada, en el numero de Junio de la Revista Artroscopia. (<a href="https://www.revistaartroscopia.com/index.php/revista/article/view/72" target="new">https://www.revistaartroscopia.com/index.php/revista/article/view/72</a>)
            </p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/acromioclavicular.jpeg" alt="Luxación Acromioclavicular" />
            <h3>Luxación Acromioclavicular</h3>
            <p>
                La luxación acromioclavicular aguda, se genera en por caidas y apoyos en piso con en hombro¡, al tratar de retirar el crane del impacto, el macanismo clasico es la rodada, tipico en deportes coomo el ciclismo y accidentes en motocicleta. En general le ofrecemos cirugía a los pacientes con lesiones tipo 4 y 5, cuando hay una completa luxación de la clavicula con el hueso del acromion (hombrera). Nuestra tecnica de elección es la fijacion artroscopica coracoclavicular y acromioclavicular con cerclajes de cintas “super-suturas”, la cual denominamos Técnica “Barata, Fácil y Reproducible”.  
            </p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/labralybiceps.jpeg" alt="Patología Labral y Bíceps" />
            <h3>Patología Labral y Bíceps</h3>
            <p>
                Las lesiones Labrales superiores o en la inserción del tendon del biceps son frecuentes en pacientes que realizan actividades de lanzamiento o “over head” a repetición. En general nuestra conducta es conservadora, hasta que genera dolor y disminución del performance deportivo, en estos casos les ofrezco en general reparaciones labrales anterior y posterior, pero en la region superior optamos por la Tenodesis del biceps, tecnica que implica cortar el tendon de la glenoides y fijarlo en el humero mediante un tornillo, estamos preperando nuestra primera serie, con más de 50 pacientes operados con esta tecnica, con excelentes resultados, los mantendremos al tanto, miestras pueden revisar el caso testimonio de Thomas, campeón panamericano de Judo 2019, operado con esta tecnica hace casi 2 años. 
            </p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/fracturahumerohombro.jpeg" alt="Fractura de Hombro y Humero" />
            <h3>Fractura de Hombro y Humero</h3>
            <p>
                Es probablemente el principal dolor de cabeza de muchos de nosotros, siempre es un desafio ya que no hay 2 fracturas iguales, la opcion quirurgica se plantea cuando el desplazamiento de los fragmentos clasicos de esta lesion son mayores. Yo y mi equipo utilizamos la fijación mediante Placas anatomicas, bloqueadas con eventual uso de injerto oseo alogenico. Nuestros resultados hasn sido satisfactorios, con bajas tasa de complicaciones.
            </p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/fxclaviculaescapula.jpeg" alt="Fracturas de Clavícula y Escápula" />
            <h3>Fracturas de Clavícula y Escápula</h3>
            <p>
                Las fracturas de claviculas para nosotros, es como la apendicitis para el cirujano de abdomen, utilizamos para resolverla un abordaje mininvasivo en lines de tension de la piel, lo que dificulta la reducción pero favorece la esteticsa y disminuye las complicaciones. Esten atentos, estamos preparando nuestra serie junto con el equipo de Mutual de Seguridad con más de 300 pacientes operados, si 300 pacientes. Por otro lado las fractura de la escapula, o omaplato conocido generalmente, son poco frecuentes y se asocian a accidenetes de alta energia, pueden ver algunos casos clínicos de paciente operados de esta lesion en mi perfil de instargram <a href="https://www.instagram.com/drfelipereinares" target="new">@drfelipereinares</a>
            </p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/protesisdehombro.jpeg" alt="Protesis de Hombro" />
            <h3>Protesis de Hombro</h3>
            <p>
                Durante los ultimos años el desarrollo de las artrooplatias de hombro ha tenido un crecimiento acelerado, hoy contamos con nuevos sistemas que utilizan inteligencia artificial y tecnologias 3d para planificar nuestros implantes. La opcion de una protesis parcial en caso de necrosis de la cabeza humeral, producto de alguna secuela por fractura o enfermedad del buzo, o una artroplastia total anatomica en caso de presentar dolor y perdida progresiva de la movilidad secundario a una artrosis o secuela de una artritis inflamatoria, hasta una protesis reversa, que utilizamos en pacientes con artropatias por rotura cronica del manguito rotador o en fracturas en pacientes sobre los 65 años (seleccionados), definitivamente mejoran la funcionalidad y alivian el dolor de nuestro pacientes.  Te invitamos a consultar o enviarme un correo en caso de dudas.  
            </p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/transferencias.jpeg" alt="Transferencias Tendinosas" />
            <h3>Transferencias Tendinosas</h3>
            <p>
                Esta es otra tecnica donde fuimos pioneros, las transferencias artroscopicas, buscan reemplazar tendones del manguito rotador, que ya no son candidatos a ser reparados (por que no existen o el musculo esta remplazado por grasa, lo cual no es funcional), desde el año 2014, luego de mi formación en estas tecnicas con el Dr Philippe Valenti en Paris, referente mundial en este topico, estas cirugías son una opcion para nuestros pacientes. La transferencia de Latissimus Dorsi anterosuperior y posterior, con la transferencia de Trapecio inferior son nuestras principales opciones. Los invito a ver el video sobre la tecnica de transferencia de latissimus dorsi anterior en la seccion Videos, al igual que estar atentos a las proximas publicaciones sobre estos temas, las cuales esperamos que salgan a la luz pronto.
            </p>
        </div>
    </div>
}
export default Hombro