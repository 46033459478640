// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';

import { LeaderBox, Stats, NewsletterContact } from '../Components';
import { LeaderBoxType } from '../Types';

import { Carousel, Button, IconButton, Icon, Modal } from 'rsuite';

import DrFelipeReinares from '../ContentDeliveryNetwork/drfelipereinares.jpg'
import Logotipo from '../ContentDeliveryNetwork/logotipo.png'
import Isotipo from '../ContentDeliveryNetwork/isotipo.png'


// Imagenes
import CC0 from '../ContentDeliveryNetwork/FromAPI/CC0.jpg'
import CC1 from '../ContentDeliveryNetwork/FromAPI/CC1.jpg'
import CC2 from '../ContentDeliveryNetwork/FromAPI/CC2.jpg'
import CC3 from '../ContentDeliveryNetwork/FromAPI/CC3.jpg'

import CC20 from '../ContentDeliveryNetwork/FromAPI/CC2-0.jpg'
import CC21 from '../ContentDeliveryNetwork/FromAPI/CC2-1.jpg'
import CC22 from '../ContentDeliveryNetwork/FromAPI/CC2-2.jpg'

import CC30 from '../ContentDeliveryNetwork/FromAPI/CC3-0.jpg'
import CC31 from '../ContentDeliveryNetwork/FromAPI/CC3-1.jpg'
import CC32 from '../ContentDeliveryNetwork/FromAPI/CC3-2.jpg'
import CC33 from '../ContentDeliveryNetwork/FromAPI/CC3-3.jpg'
import CC34 from '../ContentDeliveryNetwork/FromAPI/CC3-4.jpg'

import CC40 from '../ContentDeliveryNetwork/FromAPI/CC4-0.jpg'

import CC50 from '../ContentDeliveryNetwork/FromAPI/CC5-0.jpg'
import CC51 from '../ContentDeliveryNetwork/FromAPI/CC5-1.jpg'
import CC52 from '../ContentDeliveryNetwork/FromAPI/CC5-2.jpg'

import QuienSoy from '../ContentDeliveryNetwork/FromAPI/quiensoy.jpeg'

import CVPag01 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0001.jpg'
import CVPag02 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0002.jpg'
import CVPag03 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0003.jpg'
import CVPag04 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0004.jpg'
import CVPag05 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0005.jpg'
import CVPag06 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0006.jpg'
import CVPag07 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0007.jpg'
import CVPag08 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0008.jpg'
import CVPag09 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0009.jpg'
import CVPag10 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0010.jpg'
import CVPag11 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0011.jpg'
import CVPag12 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0012.jpg'
import CVPag13 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0013.jpg'
import CVPag14 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0014.jpg'
import CVPag15 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0015.jpg'
import CVPag16 from '../ContentDeliveryNetwork/FromAPI/parawebcv_page-0016.jpg'

import CChC from '../ContentDeliveryNetwork/CChC.png'
import CAS from '../ContentDeliveryNetwork/CAS.svg'

import Codo from '../ContentDeliveryNetwork/codo.png'
import Hombro from '../ContentDeliveryNetwork/hombro.png'

import DeviceContext from '../Library/DeviceContext';
import { useHistory } from 'react-router-dom';

import jump from 'jump.js'
import { modalview } from 'react-ga';

function Home() {
    const { isMobile } = useContext(DeviceContext)
    const history = useHistory()

    const Testimonios: any[] = [
        {
        Information: <>
        <h1> <small>Carolina, 36 años</small><br /> <span>Anquilosis de Codo</span> </h1>
        <strong> DG: Anquilosis de Codo, Secuela de Fractura de Humero distal<br /> Cx: Artroplastía total de Codo de rescate. </strong>
        <p>
            Tras un accidente automovilístico de alto impacto con una serie de secuelas físicas, entre
            ellas la destrucción de mi codo quede con un brazo sin movimiento.

            Comencé la búsqueda de opiniones médicas para recuperar mi movilidad perdida y
            soñando volver a hacer algo tan sencillo como tocar mi cara, en esta búsqueda conocí al
            Dr Reinares quien me dio una esperanza con la cirugía de Protesis de Codo. Me sometí a
            dicha cirugía en el año 2016 de la que obtuve maravillosos resultados, recupere mi
            movilidad tan anhelada que me ha permitido hasta el dia de hoy realizar todas las
            actividades de mi vida diaria, Tremendamente agradecida de este gran profesional
        </p></>,
            imageModule: <Carousel key='CC0' autoplay className='CasoClinico--Id1' shape='bar'>
            <img src={CC0} alt='Imagen de caso clínico 1' />
            <img src={CC1} alt='Imagen de caso clínico 1' />
            <img src={CC2} alt='Imagen de caso clínico 1' />
            <img src={CC3} alt='Imagen de caso clínico 1' />
        </Carousel>
        },
        {
        Information: <>
        <h1> <small>Marcela, 17 años</small><br /> <span>Inestabilidad Bilateral de Hombros</span> </h1>
        <strong> DG: Inestabilidad Bilateral de Hombros<br /> Cx: Cirugía de Latarjet Bilateral. </strong>
        <p>
            Soy seleccionada Nacional de Judo, hace algunos años, los hombros
            comenzaron a salirse, lo que me impedía llevar mi vida deportiva al máximo,
            especialmente al competir. Conocí al Dr Reinares quien me dio la opción de
            realizar una cirugía para estabilizar mis hombros, primero operamos el izquierdo,
            evolucionando muy bien, lo que me permitió volver a la competencia, luego
            decidí operarme el hombro derecho, luego de esta, volví a entrenar y competir al
            100%, esto no tiene precio, Muchas gracias Dr. Reinares.
        </p></>,
            imageModule: <Carousel key='CC20' autoplay className='CasoClinico--Id2' shape='bar'>
            <img src={CC20} alt='Imagen de caso clínico 2' />
            <img src={CC21} alt='Imagen de caso clínico 2' />
            <img src={CC22} alt='Imagen de caso clínico 2' />
        </Carousel>
        },
        {
        Information: <>
        <h1> <small>Odette</small><br /> <span>Manguito Rotador</span> </h1>
        <strong> DG: Rotura Masiva Irreparable del Manguito Rotador<br /> Cx: Transferencia Artroscopica del Trapecio Inferior. </strong>
        <p>
            En mi vida cotidiana siempre estaba presente el dolor de hombro en  muchas
            ocasiones sin movilidad alguna. El vestirme cada día , que  se realiza casi en
            forma automática para mí era con dificultad. 
            Realice tratamientos médicos  indicados sin resultado, de consulta  en consulta
            médica, llegue al Dr. Reinares, quien me hablo de una cirugía innovadora, no
            realizada en Chile (2015) una Transferencia e injerto de tendones.
            Después de un periodo difícil de rehabilitación, los resultados aparecieron.
            Al día de hoy sin dolor con una movilidad con la que que puedo hacer una vida
            normal. Aparte de las rutinas diarias puedo andar en bicicleta, nadar  y subirme a
            un árbol que era lo que más me gusta cuando era  niña.   La intervención para mi
            fue un éxito rotundo.
        </p></>,
            imageModule: <Carousel key='CC30' autoplay className='CasoClinico--Id3' shape='bar'>
            <img src={CC30} alt='Imagen de caso clínico 3' />
            <img src={CC31} alt='Imagen de caso clínico 3' />
            <img src={CC32} alt='Imagen de caso clínico 3' />
            <img src={CC33} alt='Imagen de caso clínico 3' />
            <img src={CC34} alt='Imagen de caso clínico 3' />
        </Carousel>
        },
        {
        Information: <>
        <h1> <small>Yves, 23 años (Países Bajos)</small><br /> <span>Triada Terrible de Codo</span> </h1>
        <strong> DG: Triada Terrible de Codo<br /> Cx: Pull out Coronoides 1.1//OTS de Cúpula Radial// Reparación LCL. </strong>
        <p>
            Dear Dr. Reinares, I had an operation of my dislocated and fractured elbow on
            the 20th of May in Clinica Alemana. I just wanted to say thank you for the good
            care and very well done operation. I have a full recovery of my mobility with little
            to none physiotherapy. Many thanks.
        </p></>,
            imageModule: <Carousel key='CC40' autoplay className='CasoClinico--Id4' shape='bar'>
            <img src={CC40} alt='Imagen de caso clínico 4' />
        </Carousel>
        },
        {
        Information: <>
        <h1> <small>Thomas, 23 años</small><br /> <span>Triple lesión Labral Hombro</span> </h1>
        <strong> DG: Triple lesión Labral Hombro<br /> Cx: Tenodesis del Biceps // Reparación laboral anterior y posterior.</strong>
        <p>
            Seleccionado Nacional de Judo, Medalla de Oro Panamericano Lima 2019.<br /><br />
            Hace 2 años y 4 meses que me opere el hombro, Todo ha salido muy bien. Hoy
            exijo mi hombro al máximo, aguantando caídas con mi peso (100Kg) y el de mis
            contrincantes (otros 100Kg). Quede con un hombro nuevo de verdad.
        </p></>,
            imageModule: <Carousel key='CC50' autoplay className='CasoClinico--Id5' shape='bar'>
            <img src={CC50} alt='Imagen de caso clínico 5' />
            <img src={CC51} alt='Imagen de caso clínico 5' />
            <img src={CC52} alt='Imagen de caso clínico 5' />
        </Carousel>
        }
    ]  
    const [internal] = useState(Testimonios.sort(() => Math.random() - 0.5))
    const [testiIndex, setTestiIndex] = useState(0)
    const nextBtn = <IconButton icon={<Icon icon="angle-double-right" />} appearance={'primary'} onClick={() => { nextTestimonio() }} className='Testimonio--Next'>Próximo Testimonio</IconButton>
    const nextTestimonio = () => {
        const nextNdx = testiIndex + 1 > (internal.length - 1) ? 0 : testiIndex + 1
        setTestiIndex(nextNdx)
    }

    const [modalCV, setModalCV] = useState(false)

    
    const [currentTestimonio, setCurrentTestimonio] = useState({
        ...internal[testiIndex],
        Information: <>{nextBtn}{internal[testiIndex].Information}</>
    })

    useEffect(() => {
        setCurrentTestimonio({
        ...internal[testiIndex],
        Information: <>{nextBtn}{internal[testiIndex].Information}</>
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testiIndex, setCurrentTestimonio])
  
  const showCV = () => {
    modalview('curriculum')
    setModalCV(true)
  }

  const LeaderBoxes: LeaderBoxType[] = [
    {
      id: 'home',
      align: 'left',
      Information: <div className="Wrapper">
        {isMobile ? <img src={Isotipo} alt='Isotipo completo' className="LeaderBox--Information--Isotipo" /> : <img src={Logotipo} alt='Logotipo completo' className="LeaderBox--Information--Logotipo" />}
        <div className="placementText">
          <h1>Cirugía Hombro y Codo</h1>
          <p>Dr. Felipe Reinares Silva<br />Especialista en Cirugía de Hombro y Codo<br /><br />Clínica Alemana de Santiago<br />Mutual de Seguridad</p>
          <p>
          
          </p>
        </div>
      </div>,
      imageModule: DrFelipeReinares,
      height: 380
    },
    {
      id: 'aboutme',
      align: 'right',
      Information: <>
      <h1>
        <small>Dr. Felipe Reinares</small><br />
        <span>¿Quién soy?</span>
      </h1>
      <p>Desde el <strong>año 2015</strong>, al regresar de mi formación como subespecialista en Francia, estoy 100% 
        dedicado al tratamiento de pacientes con <strong>lesiones traumáticas, degenerativas y deportivas</strong> de <i>Hombro 
        y Codo</i>. Dividiendo mi practica clínica en pacientes con accidentes o secuelas en el Hospital Clínico 
         <i> Mutual de Seguridad CChC</i> y a pacientes privados con lesiones traumáticas deportivas o domésticas y 
        lesiones degenerativas en <i>Clínica Alemana de Santiago</i>.</p>
        <IconButton icon={<Icon icon="angle-double-right" />} appearance={'primary'} onClick={() => { showCV() }} className='QuienSoy--CV'>Currículum</IconButton>
        <Modal full dialogClassName={isMobile ? 'isMobile' : 'isDesktop'} show={modalCV} onHide={() => { setModalCV(false) }}>
          <Modal.Header>
            <Modal.Title>Currículum Felipe Reinares</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={CVPag01} alt='' />
            <img src={CVPag02} alt='' />
            <img src={CVPag03} alt='' />
            <img src={CVPag04} alt='' />
            <img src={CVPag05} alt='' />
            <img src={CVPag06} alt='' />
            <img src={CVPag07} alt='' />
            <img src={CVPag08} alt='' />
            <img src={CVPag09} alt='' />
            <img src={CVPag10} alt='' />
            <img src={CVPag11} alt='' />
            <img src={CVPag12} alt='' />
            <img src={CVPag13} alt='' />
            <img src={CVPag14} alt='' />
            <img src={CVPag15} alt='' />
            <img src={CVPag16} alt='' />
          </Modal.Body>
        </Modal>
      </>,
      imageModule: QuienSoy
    },
    {
      id: 'testimonios',
      align: 'left',
      ...currentTestimonio
    },
    {
      id: 'publicaciones',
      align: 'right',
      Information: <>{isMobile && <h1>
        <small>desde el 2015</small><br />
        <span>Publicaciones y Premios</span>
      </h1>}<Stats /></>,
      imageModule: <>
        {!isMobile && <h1>
            <small>desde el 2015</small><br />
            <span>Publicaciones y Premios</span>
        </h1>}
        <p>
          En mi trayectoria técnico profesional me he visto potenciado de manera acelerada, gracias a las valiosas instituciones en las que
          estoy colaborando, y de esta manera desarrollar mis habilidades y destrezas quirúrgicas, 
          también mejorando mis debilidades. Con empatía y entusiasmo seguimos mejorando junto a mi equipo de 
          trabajo, <strong>la oportunidad</strong> de los pacientes a acceder a técnicas quirúrgicas avanzadas y al desarrollo 
          tecnológico, tratando de generar nuevo conocimiento y ciencia en nuestro que hacer.
        </p>
      </>
    },
    {
      id: 'cirugias',
      align: 'full',
      Information: <>
        <div className="CirugiaBlock Cirugias--Hombro">
            <img style={{ maxHeight: 163, maxWidth: 120 }} src={Hombro} alt="Cirugias" />
            <br /><br /><h4>Cirugías de Hombro</h4>
            <Button onClick={() => { 
                jump(-5000)
                history.push('/cirugias-hombro')
            }} color='orange'>Conocer más</Button>
            <div>Conoce sobre mis procedimientos 
                Patología Manguito Rotador,
                Inestabilidad,
                Luxación Acromioclavicular,
                Patología Labral y Bíceps,
                Fractura de Hombro y Humero,
                Fracturas de Clavícula y Escápula,
                Protesis de Hombro,
                Transferencias Tendinosas
            </div>
        </div>
        <div className="CirugiaBlock Cirugias--Codo">
            <img src={Codo} alt="Cirugias" />
            <br /><br /><h4>Cirugías de Codo</h4>
            <Button onClick={() => { 
                jump(-5000)
                history.push('/cirugias-codo')
            }} color='orange'>Conocer más</Button>
            <div>Conoce sobre mis procedimientos 
                Luxofracturas del Codo,
                Luxación e Inestabilidad de Codo,
                Fracturas de cupula radial,
                Fracturas Humero distal,
                Lesiones del Biceps o Triceps,
                Protesis total de Codo y
                Epicondilits
            </div>
        </div>
      </>
    },
    {
      id: 'reservahoras',
      align: 'full',
      Information: <div className="Map">
        <div className="CChC">
          <iframe title="CChC" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.708026733749!2d-70.70287648462464!3d-33.45691260523037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c48859b77c65%3A0xdd128fa2aec36f95!2sHospital%20Cl%C3%ADnico%20Mutual%20de%20Seguridad!5e0!3m2!1ses-419!2scl!4v1595103333123!5m2!1ses-419!2scl" width="100%" height="540" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
          <img src={CChC} className='Provider--Logotipo' alt='Logo de CChC'/>
          <div className="spacer">
            <Button href='tel:+56226775200' color='orange' block>Reservar hora: {isMobile && <br />}<strong>Mutual de{isMobile && <br />} Seguridad</strong></Button>
          </div>
        </div>
        <div className="CADS">
          <iframe title="CADS" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.1812326280024!2d-70.57520518462643!3d-33.39243570192371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662ced513235f8f%3A0xb555d18f0071c68c!2sCl%C3%ADnica%20Alemana!5e0!3m2!1ses-419!2scl!4v1595103453431!5m2!1ses-419!2scl" width="100%" height="540" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
          <img src={CAS} className='Provider--Logotipo' alt='Logo de CADS'/>
          <div className="spacer">
    <Button href='https://www.clinicaalemana.cl/profesional/perfil?IdMedico=5751003' color='orange' block>Reservar hora: {isMobile && <br />}<strong>Clínica Alemana {isMobile && <br />}de Santiago</strong></Button>
          </div>
        </div>
      </div>,
      height: isMobile ? 'auto' : 600
    },
    {
      id: 'newsletter',
      align: 'full',
      Information: <NewsletterContact />,
      height: isMobile ? 'auto' : 400
    }
  ]


    return <>{LeaderBoxes &&
            LeaderBoxes.map( (lb, ndx) => 
              <LeaderBox {...lb} key={`LeaderBox--Id${ndx}`} />
        )}</>
}

export default Home;
