import React from 'react'

type DeviceContextProps = {
    isMobile?: boolean
    YOffset?: number
    loading?: boolean
    setLoading(state: boolean): void
}

const base: DeviceContextProps = { isMobile: false, YOffset: 0, loading: false, setLoading: () => {} }

const DeviceContext = React.createContext(base)

export const DeviceProvider = DeviceContext.Provider
export default DeviceContext