import React from 'react';

const Codo = () => {
    return <div className="Cirugias--Page">

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/lxfxdecodo.jpeg" alt="Luxofracturas del Codo" />
            <h3>Luxofracturas del Codo</h3>
            <p>Las luxofracturas de codos en una lesion grave que en general, define secuelas funcionales en los pacientes, hay patrones especificos que se producen a partir del mecanismo especifico de caida, posicion de la mano y el codo al momento de caer. El tratamiento protocolizado de estas lesiones produce resultados satisfactorios en cuanto al rango de movilidad e indices funcionales.</p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/luxeinestabildiad.jpeg" alt="Luxación e Inestabilidad de Codo" />
            <h3>Luxación e Inestabilidad de Codo</h3>
            <p>La inestabilidad recurrente de codo, se produce como secuela de un evento de luxación o como consecuencia de un mecanismo de microtrauma repetitivo en con codo en una posicion de stress, como ocurre en los paciente lanzadores y se produce una elongación y daño cronico del complejo medial del codo. Nuestra opcion es la reconstruccion con auto o aloinjerto y fijacióon mediante la tecnica de Docking.</p>
        </div>
        
        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/fxcupularadial.jpeg" alt="Fracturas de cupula radial" />
            <h3>Fracturas de cupula radial</h3>
            <p>El tratamiento de las fracturas de la cupula radial aislada, no en contexto de una luxación , depende de la clínica del paciente (especialmente de la movilidad pasiva del codo en rotaciones) y de la imagen obtenida. Puede ser un manejo conservador, con ejercicios precocez, pasando por la fijación con tornillos, tecnica de tripode (evitamos el uso de placas de cupula radial, por su alta tasa de retiro y posibilidad de limitacionde. Rom) Hasta el remplazo protesico, que hace 7 años lo hacemos con un modelo especifico de radio simetrico, y vastago “suelto”, diseñado por el Dr Graham King, con quien tuve la oportuniodad de realizar un Observership en London, Ontario, Canada.</p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/fracturadehumerodistal.jpeg" alt="Fracturas Humero distal" />
            <h3>Fracturas Humero distal</h3>
            <p>En estas fracturas soy particularmente estricto, la gran mayoria son de manejo quirurgico, con seguir los principios propuestos por la Clínica Mayo. Tratamos de seguirlos de manera absoluta, lo cual ha generado excelentes resultados funcionales y de recuperacion de rango. Utilizamos placas anatomicas paralelas, con multiples tornillos articulares, cruzados, todos fijos a la placa.</p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/lesionbiceps.jpeg" alt="Lesiones del Biceps o Triceps" />
            <h3>Lesiones del Biceps o Triceps</h3>
            <p>Las lesiones por rotura del biceps distal son cada vez más frecuentes, es una cirugía que realizamos de manera precoz, ojala durante los primeros 10 dias, con un sistema de una incision en la region anteror del codo, y lo fijamos con un dispositivo especifico tipo boton cortical, lo que nos permite realizar una rehabilitación autoasistida precoz para el regreso a la actividad sin limites al 4 mes postoperatorio.</p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/protesisdecodo.jpeg" alt="Protesis total de Codo" />
            <h3>Protesis total de Codo</h3>
            <p>Es una excelente alternativa en paciente muy saleccionados, reemplazamos completamente la articulacion del codo permitiendo a los pacientes recuoperar su movilidad en ocaciones tan anhelada (los invito a revisar el testimonio de Carolina, paciente de 36 años). Es importante recalcar que es un implante que tiene restricciones con respecto a la posibilidad de carga con la extremidad posteriormente.</p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/epicondilitis.jpeg" alt="Epicondilits" />
            <h3>Epicondilits</h3>
            <p>En general es una patologia de manejo conservador, solo el 2% llega a un manejo quirurgico, dentro del manejo conservador, utilizamos ejercicios, farmacos orales ,infiltraciones con corticoides y muy anecdoticamente otros preparados como PRP (el cual no tiene evidencia suficiente). Para el escaso numero de paciente que llega a cirugía le ofrezco una tecnica artroscopica de reseccion del tendón responsable de la lesion. Con el fin de retornar al deporte en 12 a 16 semanas.</p>
        </div>

        <div className="Block">
            <img src="https://cdn.cirugiahombroycodo.cl/atrapamientocubital.jpeg" alt="SD de Atrapamiento Cubital" />
            <h3>SD de Atrapamiento Cubital</h3>
            <p>El atraimiento cubital , se caracteriza por síntomas sensitivos específicos del nervio, como parestesias y disestesias (sensaciones de hormigueo) en dedo anular y meñique, dolor en region interna del codo, asociado a hipersensibilidad local y sensación de dolor electrico desde el codo a la mano. En etapas avanzadas podemos encontrar debilidad de la mano de algunos movimientos de la mano y sensación de perdida de motricidad fina. El manejo quirúrgico se indica cuando los síntomas no ceden al manejo conservador (Fármacos y reposo) y especialmente cuando los pacientes presentan síntomas motores. Siempre lo realizamos una transposición subcutánea de manera abierta, con un abordaje posterior, para resecar parte del septum intermuscular medial, fuente de recidiva del problema de manera frecuente en otras técnicas.</p>
        </div>

        
    </div>
}
export default Codo