import React, { useEffect, useState, useContext } from 'react'

import CountUp from 'react-countup'
import { Row, Col, Modal, IconButton, Icon, List, FlexboxGrid } from 'rsuite'
import TrackVisibility from 'react-on-screen'

import ProtesicasIcon from '../ContentDeliveryNetwork/Icons/protesicas.svg'
import ScalpelIcon from '../ContentDeliveryNetwork/Icons/scalpel.svg'
import CintaIcon from '../ContentDeliveryNetwork/Icons/cinta.svg'
import UniversityIcon from '../ContentDeliveryNetwork/Icons/university.svg'
import ArtroscopiaIcon from '../ContentDeliveryNetwork/Icons/artroscopia.svg'
import TransferenciasIcon from '../ContentDeliveryNetwork/Icons/transferencias.svg'

import './Stats.scss'
import DeviceContext from '../Library/DeviceContext'
import { modalview } from 'react-ga'

const StatsModule = ({ qty, text, miny, icon }: any) => {
    return <div className='StatsModule'>
        <div className='Icon'>
            <img src={icon} alt='Imagen de Stats Module' />
        </div>
        <div className='Texts'>
            <CountUp redraw={true} end={qty} duration={4} />
            <h4>{text}{miny && <><br /><small>{miny}</small></>}</h4>
        </div>
    </div>
}

const StatsBlock = ({ isVisible }: any) => {
    const { isMobile } = useContext(DeviceContext)
    const [count, setCount] = useState(false)

    const [d1, setD1] = useState(0)
    const [d2, setD2] = useState(0)
    const [d3, setD3] = useState(0)
    const [d4, setD4] = useState(0)
    const [d5, setD5] = useState(0)
    const [d6, setD6] = useState(0)

    const [modal, setModal] = useState(false)

    useEffect(() => {
        if (!count && isVisible){
            setCount(true)

            setD1(1130)
            setD2(370)
            setD3(55)
            setD4(25)
            setD5(5)
            setD6(22)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])

    const data: any[] = [
        {
            qty: d1,
            text: 'Cirugías',
            icon: ScalpelIcon
        },
        {
            qty: d2,
            text: 'Artróscopia',
            icon: ArtroscopiaIcon
        },
        {
            qty: d3,
            text: 'Protésicas',
            icon: ProtesicasIcon
        },
        {
            qty: d4,
            text: 'Transferencias Tendinosas',
            icon: TransferenciasIcon
        },
        {
            qty: d5,
            text: 'Premios Científicos',
            miny: 'Nacionales e Internacionales',
            icon: CintaIcon
        },
        {
            qty: d6,
            text: 'Publicaciones',
            miny: 'indexadas, no indexadas y capítulos',
            icon: UniversityIcon
        }
    ]


    type DownloadLink = {
        title: string
        url: string
        description: string
    }
    const downloads: DownloadLink[] = [
        {
            title: 'Comparison of arthroscopically assisted transfer of the latissimus dorsi with or without partial cuff repair for irreparable postero-superior rotator cuff tear',
            url: 'https://cdn.cirugiahombroycodo.cl/1_comparisson.pdf',
            description: 'Inter Ortho Feb 2019'
        },
        {
            title: 'Cirugía de Latarjet-Bankart Artroscópico; Evaluación Tomográfica de la Posición del Bloque Óseo',
            url: 'https://cdn.cirugiahombroycodo.cl/2-cirugia-latarjet.pdf',
            description: 'Artroscopia Jun 2020'
        },
        {
            title: 'Transferencias tendineas alrededor del hombro para paciente con secuelas de lesiones de plexo braquial',
            url: 'https://cdn.cirugiahombroycodo.cl/3-transferencias-tendinosas.pdf',
            description: 'Contacto Científico Ene 2019'
        },
        {
            title: 'Desarrollo de modelos específicos para lesiones degenerativas o para fracturas',
            url: 'https://cdn.cirugiahombroycodo.cl/4-desarrollo-modelos-especificos.pdf',
            description: 'Contacto Científico Dic 2014'
        },
        {
            title: 'Effect of scapular external rotation on the axillary nerve during the arthroscopic Latarjet procedure: an anatomical investigation',
            url: 'https://cdn.cirugiahombroycodo.cl/5-effect-of-scapular.pdf',
            description: 'KSSTA Jun 2016'
        },
        {
            title: 'Transferencias tendíneas alrededor del hombro para paciente con secuelas de lesiones de plexo braquial',
            url: 'https://cdn.cirugiahombroycodo.cl/6-actortopmex2020.pdf',
            description: 'Acta Ortop Mex 2020'
        },
        {
            title: 'Análisis Biomecánico de dos técnicas de Hill Sachs Remplissage en un modelo animal de Cordero',
            url: 'https://cdn.cirugiahombroycodo.cl/7-BiomecanicoHS2020.pdf',
            description: 'The open Orthopaedics Journal 2020'
        },
        {
            title: 'Factibilidad de transferencia de Trapecio inferior extendido con fascia del Infraespinoso, Estudio cadavérico',
            url: 'https://cdn.cirugiahombroycodo.cl/8-LTfasciainfra2020.pdf',
            description: 'Eur J Orthop Surg Traumatol. 2020'
        }
    ]
        
        
    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto'
      };

    const showDescargables = () => {
        modalview('descargables')
        setModal(true)
    }
      
        
    return <div className="Publicaciones--Premios">
        <Row className="show-div">
            {data.map(((instance, key) => <Col xs={24} md={12} key={`Instance--of--${key}`}><StatsModule {...instance} /></Col>))} 
        </Row>
        <Modal full dialogClassName={isMobile ? 'isMobile' : 'isDesktop'} show={modal} onHide={() => { setModal(false) }}>
          <Modal.Header>
            <Modal.Title>Archivos descargables</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <List hover>
                {downloads.map((instance, index) => (
                    <a href={instance.url} download key={index} ><List.Item>
                         <FlexboxGrid>
                            <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                <Icon
                                icon='file-download'
                                style={{
                                    color: 'darkgrey',
                                    fontSize: '2em'
                                }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                colspan={20}
                                style={{
                                    ...styleCenter,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    overflow: 'hidden'
                                }}
                            >
                                {instance.title}<br />
                                <small>{instance.description}</small>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        
                    </List.Item></a>
                ))}
            </List>
          </Modal.Body>
        </Modal>
        <IconButton
            icon={<Icon icon="file-download" />}
            appearance={'primary'}
            onClick={() => { showDescargables() }}
            className='Publicaciones--Premios--Descargas'>Descargar Publicaciones</IconButton>
    </div>
}

const Stats = () => {
    return <TrackVisibility partialVisibility>
        <StatsBlock />
    </TrackVisibility>
}

export default Stats